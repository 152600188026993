.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  border: 1px solid #003064;
  border-radius: 2px;
  margin-bottom: 20px;
  z-index: 1;
  cursor: pointer;
}

.radio_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  border: 1px solid #003064;
  border-radius: 2px;
  margin-bottom: 20px;
  z-index: 1;
  cursor: pointer;
}

.container_input {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container_icon_tooltip_div {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.label {
  cursor: pointer;
  font-family: 'BentonSans-Regular', sans-serif;
  font-size: 14px;
  width: 100%;
  color: #003064;
  cursor: pointer;
}

.input_container {
  align-items: center;
}

.input {
  margin-top: 17px;
  margin-bottom: 17px;
  margin-left: 27px;
  margin-right: 13px;
  cursor: pointer;
}

/* 2023-06-16 Checkbox-Größen-Problem iOS fix */
.input_checkbox {
  margin-top: 17px;
  margin-bottom: 17px;
  margin-left: 27px;
  margin-right: 13px;
  cursor: pointer;
  min-width: 20px;
}

.grafik {
  height: 27px;
  cursor: pointer;
  margin-right: 20px;
}

.buttonStyle {
  border: 1px solid #003064;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
  background: white;
  color: #003064;
  min-width: 120px;
}

/** Textfeld **/
.textfeld {
  width: 100%;
  height: 38px;
  margin-top: 13px;
  border: 1px solid black;
  border-radius: 2px;
}
.textfeld:invalid {
  border: 2px solid red;
}

/**NUMBER ANSWER**/

.number_icon_container {
  background-color: #003064;
  grid-area: icon;
}

.number_icon {
  font-family: 'BentonSans-Bold', sans-serif;
  color: #ffffff;
  font-size: 16px;
  text-align: center;

  margin: 0;
  padding: 0;
  position: relative;
  top: 30%;
}

.number_input {
  grid-area: inputfield;
  font-family: 'BentonSans-Regular', sans-serif;
  width: 100%;
  padding-left: 13px;
  border: 1px solid #003064;
  border-top-left-radius: 2px;
  border-bottom-right-radius: 2px;
  height: 50px;
}
.number_input:invalid {
  border: 2px solid red;
}
.number_input:invalid ~ p {
  color: red;
  display: block;
}
.number_input_invalid_p {
  display: none;
  grid-area: invalidmessage;
}

/**DATE ANSWER**/

.date_input_container {
  border: 1px solid #003064;
  border-radius: 2px;
  display: flex;
  height: 55px;
}

.date_icon_container {
  width: 8%;
  height: 100%;
  background-color: #003064;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.date_input {
  font-family: 'BentonSans-Regular', sans-serif;
  width: 100%;
  height: 100%;
  border: none;
  padding-left: 13px;
}

/**Matrix Styles**/
.matrix {
  table-layout: fixed;
  border-spacing: 0 10px;
  overflow: scroll;
}
.matrix_row {
  background: #f4f4f4 0% 0% no-repeat padding-box;
}
.matrix_first_column {
  text-align: left;
  border-top: 1px solid #575757;
  border-bottom: 1px solid #575757;
  border-left: 1px solid #575757;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.matrix_first_row {
  text-align: center;
  vertical-align: middle;
}
.matrix_cell {
  text-align: center;
  vertical-align: middle;
  border-top: 1px solid #575757;
  border-bottom: 1px solid #575757;
  min-width: 45px;
  min-height: 45px;
}
.matrix_cell:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-right: 1px solid #575757;
}

.matrix_font {
  font: normal normal normal 14px/17px BentonSans-Regular;
  letter-spacing: 0px;
  color: #003064;
  margin-left: 10px;
  margin-right: 10px;
}
