@font-face {
  font-family: 'BentonSans-Regular';
  src: url('./fonts/BentonSans-Regular.eot');
  src: url('./fonts/BentonSans-Regular.eot') format('embedded-opentype'),
    url('./fonts/BentonSans-Regular.woff') format('woff'),
    url('./fonts/BentonSans-Regular.ttf') format('truetype'),
    url('./fonts/BentonSans-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BentonSans-Regular';
  src: url('./fonts/BentonSans-Bold.eot');
  src: url('./fonts/BentonSans-Bold.eot') format('embedded-opentype'),
    url('./fonts/BentonSans-Bold.woff') format('woff'),
    url('./fonts/BentonSans-Bold.ttf') format('truetype'),
    url('./fonts/BentonSans-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}
html,
body {
  margin: 0px;
  box-sizing: border-box;
  background: white;
  font-family: 'BentonSans-Regular', 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 16px;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

h2 {
  font-size: 1.17em;
  margin: 0px;
}

/* high contrast for accessibility */
input::placeholder,
input::-moz-placeholder {
  color: #575757;
  font-size: 14px;
  opacity: 1;
}

textarea:focus,
input:focus {
  outline: 2px solid #003064;
}

/*.searchable-input__active is the data list on the start page */
.button:focus,
textarea:focus,
.searchable-input__active,
input:focus {
  outline: 4px solid #005bbd;
}

/* used to align the align the text in the tooltip*/
.__react_component_tooltip .multi-line {
  text-align: left !important;
}

/*used to hide the content to the normal user but not to the screen reader */
.screenreader-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
